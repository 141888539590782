var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var t5,Fsa,Gsa,Hsa,v5,y5,z5,A5,Isa,Jsa,D5,B5,C5,G5,H5,Ksa,Lsa,I5,Msa,Nsa,Osa,Psa,Qsa,Rsa,Ssa,K5,Tsa,Usa,Vsa,L5,Wsa,Xsa,M5,Ysa,Zsa,$sa,ata;t5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=t5($CLJS.K.g(a,b),c),$CLJS.pd(c)?$CLJS.bk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.bk.g(a,b)}return a};
Fsa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Kl(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Cb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ne.j(l,$CLJS.Cb(v),m)):f}(a,b,c,d)};Gsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Fsa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.u5=function(a){return $CLJS.wD.h($CLJS.DD(a))};
Hsa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.$O,$CLJS.z(b))?$CLJS.zV(d,function(){var f=$CLJS.PV.h(d);return $CLJS.n(f)?f:$CLJS.yV(c)}()):d;return Gsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A);C=$CLJS.E.g($CLJS.u5(C),$CLJS.u5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):
$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.E.g($CLJS.u5(x),$CLJS.u5(c))?e:x,m($CLJS.Hc(t)))}return null}},null,null)}(f)}())})};
v5=function(a,b,c,d){var e=$CLJS.Bz(a,b);if($CLJS.n(e)){var f=$CLJS.u5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.fd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.jk.h($CLJS.ik.g($CLJS.Tg([f]),$CLJS.u5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QD],null))&&$CLJS.Le(function(l){return $CLJS.L1(l,$CLJS.qA)},e)?null:e;if($CLJS.y(e))return $CLJS.XK(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EM,$CLJS.sK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.Uh($CLJS.HD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Cx,$CLJS.KV,$CLJS.sK,$CLJS.Bz(a,b),$CLJS.LV,$CLJS.Bz(a,$CLJS.od(b)),$CLJS.FK,d,$CLJS.MV,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EM,$CLJS.QD],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.wW.v(a,$CLJS.od(b),$CLJS.bk,k):t5(a,b)}return a};$CLJS.w5=function(a,b){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.pK);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.x5=function(a,b){a=$CLJS.QV.l(a,b,$CLJS.bk,$CLJS.H([$CLJS.QD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.jk.h($CLJS.ik.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.JY,null,$CLJS.IY,null],null),null),$CLJS.LJ)),$CLJS.Y_.j(a,b,$CLJS.FV(a,b)))};
y5=function(a,b,c,d){var e=$CLJS.U1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.pG(function(f){return $CLJS.Y1.v(a,b,f,d)},c),(e=!$CLJS.Dd(c,null))?(e=$CLJS.U1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Le(function(f){return $CLJS.U1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
z5=function(a,b){var c=$CLJS.I3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QD],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.YE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$O],null)],null),function(){return function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.wd(l)){var m=
$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.F(c,x);$CLJS.QD.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,x,$CLJS.QD],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.QD.h(m)instanceof $CLJS.M)l=$CLJS.Hc(l);else return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,u,$CLJS.QD],null),k($CLJS.Hc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,x,$CLJS.sK],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,u,$CLJS.sK],null),k($CLJS.Hc(l)))}return null}},null,null)}(d)}()]))};
A5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.K.g(e,$CLJS.oF),k=$CLJS.K.g(e,$CLJS.uQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.qG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.oF.h($CLJS.dd(m)),f)&&$CLJS.E.g($CLJS.uQ.h($CLJS.dd(m)),k)&&$CLJS.E.g($CLJS.fd(m),l)},$CLJS.Hl($CLJS.xr,$CLJS.OQ.h($CLJS.FV(a,b)))))};
Isa=function(a,b,c,d){c=A5(a,b,c);return $CLJS.n(c)?$CLJS.QV.l(a,b,$CLJS.wW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.OQ,c,2,1],null),$CLJS.ik.g(function(e){return $CLJS.X0($CLJS.dl,e)},$CLJS.Ak),d])):a};Jsa=function(a,b,c){c=A5(a,b,c);return $CLJS.n(c)?$CLJS.QV.l(a,b,v5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OQ],null),$CLJS.Bz($CLJS.FV(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OQ,c],null)),b])):a};
D5=function(a,b,c,d,e,f){var k=$CLJS.QV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.u5(e);return $CLJS.hk.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(m,t){try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.$O))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var J=$CLJS.yV(e);J=B5.W?B5.W(k,b,c,$CLJS.qA,$CLJS.N,J):B5.call(null,k,b,c,$CLJS.qA,$CLJS.N,J);return C5.v?C5.v(J,b,c,l):C5.call(null,J,b,c,l)}()],null);throw $CLJS.Z;}catch(J){if(J instanceof Error)if(m=J,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.YE))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=B5.W?B5.W(k,b,c,$CLJS.YE,$CLJS.N,l):B5.call(null,k,b,c,$CLJS.YE,$CLJS.N,l);return C5.v?C5.v(S,b,c,l):C5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error)if(m=J,m===$CLJS.Z)try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.XE))return new $CLJS.P(null,
1,5,$CLJS.Q,[C5.v?C5.v(k,b,c,l):C5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.QD))return new $CLJS.P(null,1,5,$CLJS.Q,[C5.v?C5.v(k,b,c,l):C5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ce(C,$CLJS.EM))try{var G=$CLJS.F(t,2);if($CLJS.ce(G,$CLJS.QD))return new $CLJS.P(null,1,5,$CLJS.Q,[C5.v?C5.v(k,b,c,l):C5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw X;}else throw v;
else throw S;}else throw m;else throw J;}}($CLJS.xf,d)))):k};
B5=function(a,b,c,d,e,f){var k=$CLJS.FV(a,b),l=$CLJS.df(function(t){var u=$CLJS.Bz(k,t);return $CLJS.n(u)?$CLJS.Gl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function G(A,C){try{if($CLJS.vd(C)&&3===$CLJS.D(C))try{var J=$CLJS.F(C,0);if($CLJS.E.g(J,d))try{var S=$CLJS.F(C,1);if($CLJS.pd(e)||$CLJS.W0($CLJS.Wg(e),$CLJS.Wg(S)))try{var X=$CLJS.F(C,2);if($CLJS.E.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Z;}catch(da){if(da instanceof Error){var W=
da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)return $CLJS.AU(G,A,C);throw W;}throw da;}}($CLJS.xf,v))))},u):null},$CLJS.H([z5(a,b)])),m=$CLJS.Ve($CLJS.ac($CLJS.xf));a=$CLJS.cb(function(t,u){var v=$CLJS.I(u,0,
null);u=$CLJS.I(u,1,null);return D5(t,b,c,v,u,function(x,A,C){try{return v5(x,A,C,b)}catch(J){if(J instanceof Error){A=J;var G=$CLJS.O($CLJS.Ml(A));C=$CLJS.K.g(G,$CLJS.Cx);G=$CLJS.K.g(G,$CLJS.LV);if($CLJS.E.g(C,$CLJS.KV))return m.nd(null,$CLJS.te(m.Lb(null),G)),x;throw A;}throw J;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.E5.j?$CLJS.E5.j(t,b,u):$CLJS.E5.call(null,t,b,u)},a,$CLJS.cc($CLJS.q(m)))};
C5=function(a,b,c,d){b=$CLJS.w5(c,b);if($CLJS.n(b)){var e=$CLJS.FV(c,b);c=$CLJS.Me(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.LJ),l=$CLJS.K.g(f,$CLJS.F0);return $CLJS.E.g($CLJS.xY,k)&&$CLJS.E.g(d,l)?$CLJS.MY.h(f):null},$CLJS.$_.j(c,b,e));return $CLJS.n(c)?B5(a,b,a,$CLJS.iF,$CLJS.N,c):a}return a};
G5=function(a,b,c,d,e){var f=$CLJS.rV.h(c),k=$CLJS.FV(a,b);c=$CLJS.qG(function(t){var u=$CLJS.Bz(k,t);if($CLJS.n(u)){var v=$CLJS.u5(f);return $CLJS.n($CLJS.Me($CLJS.ik.j($CLJS.Tg([v]),$CLJS.wD,$CLJS.dd),u))?t:null}return null},z5(a,b));var l=(d=$CLJS.E.g($CLJS.FF,d))?$CLJS.rV.h(e):null;e=d?function(t,u,v){return Hsa(t,u,v,l)}:function(t,u,v){return v5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.fd(f),$CLJS.fd(l))?
Isa(a,b,f,$CLJS.Il($CLJS.dd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uQ,$CLJS.oF],null))):m?Jsa(a,b,f):a;return $CLJS.n(c)?(a=D5(a,b,a,c,f,e),$CLJS.F5.h?$CLJS.F5.h(a):$CLJS.F5.call(null,a)):a};H5=function(a){return $CLJS.ED(a,$CLJS.R,$CLJS.H([$CLJS.wD,$CLJS.p.h($CLJS.GD())]))};
Ksa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.qA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return H5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.YV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Lsa=function(a,b,c){var d=function(){var l=$CLJS.yV(c);return $CLJS.n(l)?l:$CLJS.T.h($CLJS.DD(c))}(),e=H5($CLJS.zV(c,d));a=$CLJS.lk.j(a,$CLJS.$O,function(l){return $CLJS.kk.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.yV(b),k=$CLJS.pi.h($CLJS.DD(c));return Ksa(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.pi,k],null),d],null))};
I5=function(a,b,c){return $CLJS.uU(function(d){return $CLJS.E.g(d,$CLJS.u5(b))?$CLJS.u5(c):d},$CLJS.n($CLJS.yV(b))?Lsa(a,b,c):$CLJS.uU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Msa=function(a,b,c){a=$CLJS.Y_.j(a,b,$CLJS.FV(a,b));b=$CLJS.Y_.j(c,b,$CLJS.FV(c,b));return $CLJS.ff(function(d){return $CLJS.hk.g($CLJS.z(d),$CLJS.dd(d))},$CLJS.cf.j($CLJS.xr,a,b))};
Nsa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.MY.h(d),f=$CLJS.qV($CLJS.R.j(c,$CLJS.LJ,$CLJS.xY));return $CLJS.cf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,H5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Sa,function t(l,m){try{if($CLJS.vd(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ce(u,$CLJS.iF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.AU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.FV(a,b)))))};Osa=function(a,b,c){var d=$CLJS.pi.h($CLJS.DD(c));$CLJS.n(d)||(d=$CLJS.P0.h?$CLJS.P0.h(c):$CLJS.P0.call(null,c),d=$CLJS.Ra(d)||!$CLJS.xV(c));if($CLJS.n(d))return c;a=$CLJS.T_.j(a,b,c);return $CLJS.ED(c,$CLJS.R,$CLJS.H([$CLJS.pi,a]))};
Psa=function(a,b){a=$CLJS.yV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ra($CLJS.yV(b))&&$CLJS.Ra($CLJS.T.h($CLJS.DD(b))):a)?$CLJS.zV(b,a):b};
Qsa=function(a,b,c,d){d=Osa(a,b,Psa(c,d));c=$CLJS.QV.l(a,b,I5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.w5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Nsa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Msa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.QV.l(v,u,I5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Rsa=function(a,b){b=$CLJS.Sk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.pK)){var c=$CLJS.dd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Me(function(k){return $CLJS.Le($CLJS.ol,$CLJS.cf.j($CLJS.E,k,d))?k:null},z5(a,c)):f}();return $CLJS.n(e)?$CLJS.pk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Ssa=function(a,b,c,d){for(var e=Qsa(a,b,c,d);;){var f=$CLJS.GX($CLJS.LK,e),k=$CLJS.Bk.h($CLJS.Gl(function(l){return function(m){return Rsa(l,m)}}(e,f),$CLJS.Hr.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.J5.j(l,$CLJS.dd(m),$CLJS.Bz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Ml(t)),v=$CLJS.K.g(u,$CLJS.Cx);u=$CLJS.K.g(u,$CLJS.LV);if($CLJS.E.g(v,$CLJS.KV))return m=$CLJS.dd(m),$CLJS.E5.j?$CLJS.E5.j(l,m,u):$CLJS.E5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?G5(a,b,c,$CLJS.FF,d):e}};K5=function(a,b){return $CLJS.L1(a,$CLJS.iF)&&$CLJS.E.g($CLJS.m1(a),b)};Tsa=function(a,b,c){return function k(e,f){try{if(K5(f,b))return $CLJS.n3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.YV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Usa=function(a,b,c){var d=$CLJS.EM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.UD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.hk.g(e,c):b)){var f=$CLJS.IV();$CLJS.i3(f,$CLJS.cf.g($CLJS.UD,d));c=f(c);return Tsa($CLJS.XK(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,b,$CLJS.UD],null),c),e,c)}return a};
Vsa=function(a,b,c){if($CLJS.Bd(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.ik.g(e,$CLJS.UD):e}();return $CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Hl($CLJS.xr,$CLJS.EM.h($CLJS.FV(a,b))))};
L5=function(a,b,c,d){b=$CLJS.bt.g($CLJS.Wg($CLJS.$_.j(b,c,$CLJS.FV(b,c))),$CLJS.Wg($CLJS.$_.j(a,c,$CLJS.FV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.Ne.M(B5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Wsa=function(a,b,c){a=L5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,new $CLJS.h(null,1,[$CLJS.uO,$CLJS.gZ.h(d)],null),$CLJS.Ki.h(d)],null)});c=$CLJS.w5(a,c);return $CLJS.n(c)?L5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,$CLJS.N,$CLJS.MY.h(d)],null)}):a};
Xsa=function(a,b,c){return $CLJS.Bd(c)?$CLJS.Bz($CLJS.FV(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,c,$CLJS.UD],null)):$CLJS.td(c)?$CLJS.UD.h(c):c};
M5=function(a,b,c,d){var e=Xsa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.QV)(a,b,function(k){var l=$CLJS.EM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.CD(k,$CLJS.EM,l)});return(0,$CLJS.QV)(f,b,function(k){return $CLJS.TV(k,$CLJS.EM,function(l){return $CLJS.kk.g(function(m){return $CLJS.v3(f,b,m)},l)})})}(),c=Wsa(c,a,b),$CLJS.F5.h?$CLJS.F5.h(c):$CLJS.F5.call(null,c)):a};
Ysa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function f(d,e){try{if(K5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.AU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Zsa=function(a,b){var c=$CLJS.QD.h($CLJS.FV(a,b));if($CLJS.n(c)){var d=$CLJS.Ra($CLJS.Me($CLJS.ik.g($CLJS.FM,$CLJS.DD),c));return d?y5(a,b,c,$CLJS.x5(a,b)):d}return c};
$sa=function(a,b){var c=$CLJS.FV(a,b),d=$CLJS.n(Zsa(a,b))?$CLJS.QV.l(a,b,$CLJS.bk,$CLJS.H([$CLJS.QD])):a;return $CLJS.n($CLJS.EM.h(c))?$CLJS.QV.l(d,b,$CLJS.lk,$CLJS.H([$CLJS.EM,$CLJS.Qe($CLJS.kk,function(e){var f=$CLJS.QD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wx,null,$CLJS.cz,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(y5(a,b,$CLJS.QD.h(e),$CLJS.Y_.j(a,b,$CLJS.R.j(e,$CLJS.QD,$CLJS.wx))))?$CLJS.R.j(e,$CLJS.QD,$CLJS.wx):e})])):d};
$CLJS.F5=function(a){return $CLJS.cb($sa,a,$CLJS.bh(0,$CLJS.D($CLJS.pK.h(a))))};ata=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.N5=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var O5;$CLJS.J5=function(){function a(d,e,f){return $CLJS.td(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.kK)?$CLJS.E5.j?$CLJS.E5.j(d,e,f):$CLJS.E5.call(null,d,e,f):G5(d,e,f,ata,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();O5=$CLJS.lE.h($CLJS.WD);
$CLJS.Q5=function(){function a(d,e,f,k){if($CLJS.td(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.kK))d=$CLJS.P5.v?$CLJS.P5.v(d,e,f,k):$CLJS.P5.call(null,d,e,f,k);else{var l=O5.h?O5.h(f):O5.call(null,f);l=$CLJS.n(l)?O5.h?O5.h(k):O5.call(null,k):l;d=$CLJS.n(l)?Ssa(d,e,f,k):G5(d,e,f,$CLJS.FF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.bta=function(){function a(d,e,f,k){f=Vsa(d,e,f);return $CLJS.n(f)?$CLJS.QV.l(d,e,Usa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.E5=function(){function a(d,e,f){try{return M5(d,e,f,function(u,v){return $CLJS.Be($CLJS.A0(function(x){return!($CLJS.E.g($CLJS.UD.h(x),v)||Ysa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Ml(k)),m=$CLJS.K.g(l,$CLJS.FK),t=$CLJS.K.g(l,$CLJS.Cx);l=$CLJS.K.g(l,$CLJS.LV);if($CLJS.E.g(t,$CLJS.KV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P5=function(){function a(d,e,f,k){return null==k?$CLJS.E5.j(d,e,f):M5(d,e,f,function(l,m){return $CLJS.kk.g(function(t){return $CLJS.E.g($CLJS.UD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();