var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var sV,Kma,Lma,AV,Mma,Nma,Pma,wV,Qma,Oma;sV=function(a){return a+1};$CLJS.tV=function(a){if("string"===typeof a){var b=$CLJS.dh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Ol(a));};
Kma=function(a){var b=new $CLJS.Ca,c=$CLJS.ut;$CLJS.ut=new $CLJS.pc(b);try{var d=$CLJS.ut,e=$CLJS.Ra($CLJS.Cu(d)),f=$CLJS.ut;$CLJS.ut=e?$CLJS.tu(d):d;try{d=$CLJS.Eu;$CLJS.Eu=!0;try{$CLJS.Gu(a)}finally{$CLJS.Eu=d}$CLJS.E.g(0,$CLJS.Bt($CLJS.ut,$CLJS.Dt))||$CLJS.Yb($CLJS.ut,"\n");$CLJS.At()}finally{$CLJS.ut=f}$CLJS.kh($CLJS.p.h(b))}finally{$CLJS.ut=c}};$CLJS.uV=function(a,b){return $CLJS.Ne.j($CLJS.ha,a,b)};
$CLJS.vV=function(a){var b=$CLJS.qu;$CLJS.qu=120;try{var c=new $CLJS.Ca,d=$CLJS.La,e=$CLJS.Na;$CLJS.La=!0;$CLJS.Na=function(f){return c.append(f)};try{Kma(a)}finally{$CLJS.Na=e,$CLJS.La=d}return $CLJS.p.h(c)}finally{$CLJS.qu=b}};
Lma=function(a){a=$CLJS.O(a);var b=$CLJS.K.j(a,$CLJS.EU,$CLJS.Pd),c=$CLJS.K.j(a,$CLJS.BU,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Se($CLJS.N),e=$CLJS.Se($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.K.g($CLJS.zh.v(e,$CLJS.lk,u,$CLJS.QC(sV,0)),u);if($CLJS.E.g(x,1))return $CLJS.zh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==wV&&(wV=$CLJS.Se(0));var v=$CLJS.qh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.zh.g(wV,sV))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.xV=function(a){var b=$CLJS.vd(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.K.g(a,1),$CLJS.td(a)&&$CLJS.Dd(a,$CLJS.wD)):b:b};$CLJS.yV=function(a){return $CLJS.xV(a)?$CLJS.oD.h($CLJS.DD(a)):null};$CLJS.zV=function(a,b){return $CLJS.FD($CLJS.xV(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,new $CLJS.h(null,2,[$CLJS.wD,$CLJS.p.h($CLJS.GD()),$CLJS.pi,$CLJS.KD(a)],null),a],null),function(c){return $CLJS.bk.l($CLJS.R.j(c,$CLJS.oD,b),$CLJS.T,$CLJS.H([$CLJS.kD]))})};
AV=function(a,b,c){var d=$CLJS.Dd(a,b)?$CLJS.lk.j(a,b,function(e){return $CLJS.vd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.nr)?$CLJS.yf($CLJS.Xe(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Dd(a,b)?$CLJS.zU(d,$CLJS.Ae([b,c])):d};Mma=function(a){var b=$CLJS.Il(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,$CLJS.IP],null));b=BV.h?BV.h(b):BV.call(null,b);return $CLJS.pV($CLJS.R.l(AV($CLJS.bk.l(a,$CLJS.fO,$CLJS.H([$CLJS.IP])),$CLJS.QQ,$CLJS.sK),$CLJS.Vs,$CLJS.kK,$CLJS.H([$CLJS.pK,b])))};
Nma=function(a){return $CLJS.kk.g(Mma,a)};Pma=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.lk.j($CLJS.pl(a)?new $CLJS.h(null,1,[$CLJS.CV,a],null):a,$CLJS.CV,function(b){return $CLJS.kk.g(function(c){return $CLJS.R.j($CLJS.Ez(c,$CLJS.eA),$CLJS.Vs,$CLJS.JJ)},b)}),$CLJS.Vs,Oma):null};
$CLJS.DV=function(a){if($CLJS.E.g($CLJS.Vs.h(a),$CLJS.hV))return a;var b=$CLJS.Vi.h(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "native":return $CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.hV,$CLJS.pK,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ak.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Vs,$CLJS.bV],null),$CLJS.zU($CLJS.jP.h(a),new $CLJS.h(null,1,[$CLJS.EE,$CLJS.jP],null))]))],null)],null),$CLJS.bk.l(a,$CLJS.Vi,$CLJS.H([$CLJS.jP]))]));case "query":return $CLJS.Ak.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Vs,$CLJS.hV,$CLJS.pK,BV($CLJS.EE.h(a))],null),$CLJS.bk.l(a,$CLJS.Vi,$CLJS.H([$CLJS.EE]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.EV=function(a,b){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.pK);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.Uh($CLJS.fD("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Qma,$CLJS.D(a)],null));return c};
$CLJS.FV=function(a,b){a=$CLJS.DV(a);a=$CLJS.O(a);var c=$CLJS.K.g(a,$CLJS.pK);return $CLJS.K.g($CLJS.yf(c),$CLJS.EV(a,b))};$CLJS.GV=function(a){return"string"===typeof a&&(a=$CLJS.eh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.tV(a)):null};$CLJS.IV=function(){return $CLJS.ik.g(HV,Lma($CLJS.H([$CLJS.EU,$CLJS.aA,$CLJS.BU,function(a,b){return HV.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};wV=null;
$CLJS.JV=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.KV=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.LV=new $CLJS.M(null,"join","join",-758861890);$CLJS.MV=new $CLJS.M(null,"stage","stage",1843544772);Qma=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.CV=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.NV=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Oma=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.OV=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var Rma,Sma,Tma,Uma,Vma,BV,HV;Rma=$CLJS.Se($CLJS.N);Sma=$CLJS.Se($CLJS.N);Tma=$CLJS.Se($CLJS.N);Uma=$CLJS.Se($CLJS.N);Vma=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.PV=new $CLJS.Ph($CLJS.qh.g("metabase.lib.util","custom-name-method"),$CLJS.WA,Vma,Rma,Sma,Tma,Uma);$CLJS.PV.m(null,$CLJS.Oh,function(a){return $CLJS.xV(a)?$CLJS.zz($CLJS.kD,$CLJS.oD)($CLJS.DD(a)):null});
BV=function BV(a){a=$CLJS.O(a);var c=$CLJS.K.g(a,$CLJS.IP),d=$CLJS.K.g(a,$CLJS.SN);c=$CLJS.n(c)?BV.h?BV.h(c):BV.call(null,c):$CLJS.xf;d=Pma(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.XK(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.oU],null),d):c;d=$CLJS.n($CLJS.jP.h(a))?$CLJS.bV:$CLJS.VU;a=$CLJS.Ak.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Vs,d],null),$CLJS.bk.l(a,$CLJS.IP,$CLJS.H([$CLJS.SN]))]));a=$CLJS.y($CLJS.EM.h(a))?$CLJS.lk.j(a,$CLJS.EM,Nma):a;a=AV(a,$CLJS.ME,$CLJS.iV);return $CLJS.Yd.g(c,
a)};
$CLJS.QV=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.DV(c);c=$CLJS.O(c);var k=$CLJS.K.g(c,$CLJS.pK);d=$CLJS.EV(c,d);e=$CLJS.Ne.M($CLJS.lk,$CLJS.yf(k),d,e,f);return $CLJS.R.j(c,$CLJS.pK,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=
b;return a}();
HV=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.nV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.Wma=RegExp(" id$","i");