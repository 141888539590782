var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var jE,sE,pE,Vga;jE=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=jE[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=jE._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("Cached.-cache",a);}return a};
$CLJS.kE=function(a,b,c){if(null!=a&&$CLJS.yc===a.Wa){var d=jE(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.zh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.mE=function(a,b,c){a=$CLJS.lE.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.nE=function(a,b){return $CLJS.mE(a,b,null)};$CLJS.oE=function(a,b){$CLJS.zh.v($CLJS.fB,$CLJS.gA,a,b)};$CLJS.qE=function(a,b){a=pE(a,b);return $CLJS.n(a)?a:$CLJS.fj};
sE=function(){return $CLJS.Wf.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.zi,$CLJS.z,$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.hj);return $CLJS.vd(a)?["Invalid ",$CLJS.xh.l($CLJS.H([$CLJS.z(a)]))," clause: ",$CLJS.xh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"not a known MBQL clause"],null),$CLJS.Pe(!1)],null)],null)],null),$CLJS.cf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.mh.g("mbql.clause",$CLJS.Xg(a))],null)],null)}),$CLJS.q(rE))};$CLJS.vE=function(a,b){$CLJS.Ne.v($CLJS.tE,$CLJS.uE,a,b)};$CLJS.xE=function(a,b){$CLJS.Ne.v($CLJS.tE,wE,a,b)};$CLJS.xo.prototype.ia=$CLJS.ua(26,function(){return this.cache});$CLJS.zo.prototype.ia=$CLJS.ua(25,function(){return this.cache});$CLJS.Bo.prototype.ia=$CLJS.ua(24,function(){return this.cache});$CLJS.Do.prototype.ia=$CLJS.ua(23,function(){return this.cache});
$CLJS.Fo.prototype.ia=$CLJS.ua(22,function(){return this.cache});$CLJS.Ho.prototype.ia=$CLJS.ua(21,function(){return this.cache});$CLJS.Ko.prototype.ia=$CLJS.ua(20,function(){return this.cache});$CLJS.Mo.prototype.ia=$CLJS.ua(19,function(){return this.cache});$CLJS.Oo.prototype.ia=$CLJS.ua(18,function(){return this.cache});$CLJS.Qo.prototype.ia=$CLJS.ua(17,function(){return this.cache});$CLJS.So.prototype.ia=$CLJS.ua(16,function(){return this.cache});$CLJS.Uo.prototype.ia=$CLJS.ua(15,function(){return this.cache});
$CLJS.Wo.prototype.ia=$CLJS.ua(14,function(){return this.cache});$CLJS.Yo.prototype.ia=$CLJS.ua(13,function(){return this.cache});$CLJS.$o.prototype.ia=$CLJS.ua(12,function(){return this.cache});$CLJS.bp.prototype.ia=$CLJS.ua(11,function(){return this.cache});$CLJS.ep.prototype.ia=$CLJS.ua(10,function(){return this.cache});$CLJS.jp.prototype.ia=$CLJS.ua(9,function(){return this.cache});$CLJS.lp.prototype.ia=$CLJS.ua(8,function(){return this.cache});$CLJS.op.prototype.ia=$CLJS.ua(7,function(){return this.cache});
$CLJS.yp.prototype.ia=$CLJS.ua(6,function(){return this.cache});$CLJS.VC.prototype.ia=$CLJS.ua(5,function(){return this.cache});$CLJS.lE=function lE(a){switch(arguments.length){case 1:return lE.h(arguments[0]);case 2:return lE.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.lE.h=function(a){return $CLJS.lE.g(a,null)};$CLJS.lE.g=function(a,b){return $CLJS.kE($CLJS.Hn.g(a,b),$CLJS.Pj,$CLJS.Mm)};$CLJS.lE.A=2;
$CLJS.yE=function yE(a,b){return $CLJS.Hh($CLJS.OC,a,b)||$CLJS.Ad($CLJS.Me(function(d){return yE.g?yE.g(a,d):yE.call(null,a,d)},$CLJS.Dk.h(b)))||$CLJS.Ad($CLJS.Me(function(d){return yE.g?yE.g(d,b):yE.call(null,d,b)},$CLJS.Jh(a)))};
pE=function pE(a,b){return $CLJS.E.g(a,$CLJS.fj)?null:$CLJS.E.g(b,$CLJS.fj)?null:$CLJS.yE(a,b)?b:$CLJS.yE(b,a)?a:$CLJS.Me(function(d){return $CLJS.Me(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:pE.g?pE.g(d,e):pE.call(null,d,e)},$CLJS.ae(b,$CLJS.Jh(b)))},$CLJS.ae(a,$CLJS.Jh(a)))};$CLJS.zE=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.AE=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.BE=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.CE=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.DE=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.EE=new $CLJS.M(null,"query","query",-1288509510);$CLJS.FE=new $CLJS.M(null,"power","power",-937852079);$CLJS.GE=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.HE=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.IE=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.JE=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.KE=new $CLJS.M(null,"between","between",1131099276);$CLJS.LE=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.ME=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.NE=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.OE=new $CLJS.M(null,"var","var",-769682797);$CLJS.PE=new $CLJS.M(null,"next","next",-117701485);$CLJS.QE=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.RE=new $CLJS.M(null,"case","case",1143702196);
$CLJS.SE=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.TE=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.UE=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.VE=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.WE=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.XE=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.YE=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.ZE=new $CLJS.M(null,"desc","desc",2093485764);
$CLJS.$E=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.aF=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.bF=new $CLJS.M(null,"short","short",1928760516);$CLJS.cF=new $CLJS.M(null,"avg","avg",197406200);$CLJS.dF=new $CLJS.M(null,"metric","metric",408798077);$CLJS.eF=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.fF=new $CLJS.M(null,"asc","asc",356854569);$CLJS.gF=new $CLJS.M(null,"floor","floor",1882041021);
$CLJS.hF=new $CLJS.M(null,"contains","contains",676899812);$CLJS.iF=new $CLJS.M(null,"field","field",-1302436500);$CLJS.jF=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.kF=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.lF=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.mF=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.nF=new $CLJS.M(null,"round","round",2009433328);
$CLJS.oF=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.pF=new $CLJS.M(null,"share","share",-589433933);$CLJS.qF=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.rF=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.sF=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.tF=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.uF=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.vF=new $CLJS.M(null,"median","median",569566131);Vga=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.wF=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.xF=new $CLJS.M(null,"trim","trim",774319767);$CLJS.yF=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.zF=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.AF=new $CLJS.M(null,"category","category",-593092832);
$CLJS.BF=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.CF=new $CLJS.M(null,"/","/",1282502798);$CLJS.DF=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.EF=new $CLJS.M(null,"log","log",-1595516004);$CLJS.FF=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.GF=new $CLJS.M(null,"rtrim","rtrim",-661336449);var rE=$CLJS.Se($CLJS.Sg),wE;$CLJS.Y($CLJS.LD,sE());$CLJS.gB(rE,Vga,function(){return $CLJS.Y($CLJS.LD,sE())});
$CLJS.HF=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.JD.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.mh.g("mbql.clause",$CLJS.Xg(d));$CLJS.Y(f,e);$CLJS.Dd($CLJS.q(rE),d)||$CLJS.zh.j(rE,$CLJS.Yd,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
wE=function wE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
wE.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,$CLJS.Wf.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ti,new $CLJS.h(null,1,[$CLJS.ts,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null)],null)],null)],null),b)],null)};wE.A=1;
wE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};$CLJS.uE=function uE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.uE.l=function(a,b){return $CLJS.Wf.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.h(null,1,[$CLJS.ts,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null)],null),b)};$CLJS.uE.A=1;$CLJS.uE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.tE=function tE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tE.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.tE.l=function(a,b,c){c=$CLJS.E.g($CLJS.z(c),$CLJS.Zr)?$CLJS.ae($CLJS.dd(c),$CLJS.Xe(2,c)):$CLJS.ae(null,c);var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);a=$CLJS.Ne.j(a,b,d);return $CLJS.n(c)?$CLJS.HF.v(b,$CLJS.Zr,c,a):$CLJS.HF.g(b,a)};$CLJS.tE.A=2;
$CLJS.tE.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};