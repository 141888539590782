var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Cta,Dta,P7,Eta,Fta,Gta,Hta,Ita,Jta,Kta,Q7,R7,S7;
Cta=function(a){return function(b){var c=$CLJS.Ve($CLJS.Sg);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Dd($CLJS.q(c),t))return l;c.nd(null,$CLJS.Yd.g(c.Lb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Dta=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.eD(function(d){return $CLJS.$_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.J_,c,$CLJS.D_,!1],null))}),$CLJS.EM.h($CLJS.FV(a,b)))};P7=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.eD(function(d){return $CLJS.Y_.v(a,b,d,c)}),$CLJS.EM.h($CLJS.FV(a,b)))};$CLJS.E3=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.QV.l(c,d,$CLJS.R,$CLJS.H([$CLJS.s7,$CLJS.Y_.j(c,d,$CLJS.FV(c,d))]))},a,$CLJS.qt(0,$CLJS.EV(a,b)))};
Eta=function(a,b){a=$CLJS.FV(a,b);a=$CLJS.O(a);var c=$CLJS.K.g(a,$CLJS.Vs),d=$CLJS.K.g(a,$CLJS.OU);b=$CLJS.s7.h(a);if($CLJS.n(b))return b;var e=$CLJS.oU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.bV);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.XY;case "mbql.stage/mbql":return $CLJS.QY;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Be(function(){return function m(l){return new $CLJS.ie(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A);C=$CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.wY,$CLJS.T.h(C),$CLJS.MY,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.LJ,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.wY,$CLJS.T.h(x),$CLJS.MY,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.LJ,f],null)])),m($CLJS.Hc(t)))}return null}},null,null)}($CLJS.CV.h(e))}())}return null};
Fta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.id(l,v),A=t,C=$CLJS.R,G=C.l,J=x,S=$CLJS.zz($CLJS.wY,$CLJS.T)(x);x=$CLJS.o1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,J,$CLJS.LJ,$CLJS.$J,$CLJS.H([$CLJS.wY,S,$CLJS.MY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.LJ,$CLJS.$J,$CLJS.H([$CLJS.wY,$CLJS.zz($CLJS.wY,$CLJS.T)(u),$CLJS.MY,function(){var v=$CLJS.o1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.W5.g(a,b))}())};
Gta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.id(l,v),A=t,C=$CLJS.R,G=C.l,J=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,J,$CLJS.LJ,$CLJS.iK,$CLJS.H([$CLJS.wY,S,$CLJS.MY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=
$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.LJ,$CLJS.iK,$CLJS.H([$CLJS.wY,$CLJS.T.h(u),$CLJS.MY,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.y4.g(a,b))}())};
Hta=function(a,b,c){var d=$CLJS.FV(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.K.g(d,$CLJS.QD);return $CLJS.Be(function(){return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.wd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);return function(){for(var J=0;;)if(J<u){var S=$CLJS.id(t,J),X=$CLJS.I(S,0,null),W=function(){var ra=X;ra=ra instanceof $CLJS.M?ra.S:null;switch(ra){case "field":return $CLJS.VY;case "expression":return $CLJS.GY;default:throw Error(["No matching clause: ",
$CLJS.p.h(ra)].join(""));}}(),da=$CLJS.l_.j(a,b,S);$CLJS.pe(v,$CLJS.R.l(da,$CLJS.LJ,W,$CLJS.H([$CLJS.wY,$CLJS.Q_.j(a,b,da),$CLJS.MY,function(){var ra=$CLJS.o1(a,da);return c.h?c.h(ra):c.call(null,ra)}()])));J+=1}else return!0}()?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var J=A;J=J instanceof $CLJS.M?J.S:null;switch(J){case "field":return $CLJS.VY;case "expression":return $CLJS.GY;default:throw Error(["No matching clause: ",$CLJS.p.h(J)].join(""));
}}(),G=$CLJS.l_.j(a,b,x);return $CLJS.ae($CLJS.R.l(G,$CLJS.LJ,C,$CLJS.H([$CLJS.wY,$CLJS.Q_.j(a,b,G),$CLJS.MY,function(){var J=$CLJS.o1(a,G);return c.h?c.h(J):c.call(null,J)}()])),l($CLJS.Hc(m)))}return null}},null,null)}(e)}())}return null};Ita=function(a,b,c){return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.eD(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Fta,Gta],null)))};
Jta=function(a,b,c){var d=$CLJS.M1(a,b);return $CLJS.n(d)?$CLJS.Be(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.id(m,A);var G=$CLJS.zz($CLJS.MY,$CLJS.wY)(C);G=$CLJS.n(G)?G:$CLJS.Q_.j(a,b,C);C=$CLJS.bk.l($CLJS.Ak.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.LJ,$CLJS.xY,$CLJS.wY,G,$CLJS.MY,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.t1.h(C))?
$CLJS.Il(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.t1],null)):null])),$CLJS.JV,$CLJS.H([$CLJS.oD]));u.add(C);A+=1}else return!0}()?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.zz($CLJS.MY,$CLJS.wY)(v);return $CLJS.n(A)?A:$CLJS.Q_.j(a,b,v)}();return $CLJS.ae($CLJS.bk.l($CLJS.Ak.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.LJ,$CLJS.xY,$CLJS.wY,x,$CLJS.MY,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.t1.h(v))?$CLJS.Il(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.t1],null)):null])),$CLJS.JV,$CLJS.H([$CLJS.oD])),k($CLJS.Hc(l)))}return null}},null,null)}($CLJS.Y_.j(a,d,$CLJS.FV(a,d)))}()):null};Kta=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.h_(a,c),$CLJS.n(c)?$CLJS.Be($CLJS.$_.v(a,b,c,d)):null):null};
Q7=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.id(l,v),A=t;var C=$CLJS.tA.h(x);var G=$CLJS.R.l,J=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.J1(G.call($CLJS.R,J,$CLJS.LJ,$CLJS.GY,$CLJS.H([$CLJS.wY,S,$CLJS.MY,x])),$CLJS.pi,$CLJS.n(C)?C:$CLJS.fj);A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),
f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k);return $CLJS.ae(function(){var v=$CLJS.tA.h(u),x=$CLJS.R.l,A=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.J1(x.call($CLJS.R,A,$CLJS.LJ,$CLJS.GY,$CLJS.H([$CLJS.wY,C,$CLJS.MY,G])),$CLJS.pi,$CLJS.n(v)?v:$CLJS.fj)}(),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.Epa.g(a,b))}())};
R7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.K.g(d,$CLJS.J_);return $CLJS.kk.g(function(f){return $CLJS.bk.l(f,$CLJS.gZ,$CLJS.H([$CLJS.JV,$CLJS.OV,$CLJS.XJ]))},function(){var f=Jta(a,b,e);if($CLJS.n(f))return f;f=$CLJS.FV(a,b);f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.fO),l=$CLJS.K.g(f,$CLJS.OU),m=$CLJS.n(k)?function(){var t=$CLJS.f_(a,k);return $CLJS.$_.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Kta(a,b,l,$CLJS.R.j(d,$CLJS.D_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ie(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var S=0;;)if(S<C){var X=$CLJS.id(A,S),W=G,da=$CLJS.R,ra=da.l,Ma=X,sb=$CLJS.T.h(X);X=$CLJS.T.h(X);X=e.h?e.h(X):e.call(null,X);da=ra.call(da,Ma,$CLJS.LJ,$CLJS.XY,$CLJS.H([$CLJS.wY,sb,$CLJS.MY,X]));W.add(da);S+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var J=$CLJS.z(x);return $CLJS.ae($CLJS.R.l(J,$CLJS.LJ,$CLJS.XY,$CLJS.H([$CLJS.wY,$CLJS.T.h(J),
$CLJS.MY,function(){var S=$CLJS.T.h(J);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.CV.h($CLJS.oU.h(f)))}())};S7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.mra={};$CLJS.oE($CLJS.VU,S7);$CLJS.oE($CLJS.bV,S7);$CLJS.T0.m(null,$CLJS.VU,function(a){return $CLJS.S0(a,new $CLJS.h(null,2,[$CLJS.YE,$CLJS.Qe($CLJS.kk,$CLJS.T0),$CLJS.iV,$CLJS.Qe($CLJS.kk,$CLJS.T0)],null))});$CLJS.V_.m(null,S7,function(){throw $CLJS.Uh("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.Z_.m(null,S7,function(a,b,c,d){var e=$CLJS.O(d);c=$CLJS.K.g(e,$CLJS.J_);d=$CLJS.K.g(e,$CLJS.D_);a=$CLJS.E3(a,b);e=$CLJS.O(e);var f=$CLJS.K.g(e,$CLJS.J_),k=$CLJS.K.g(e,$CLJS.H_),l=$CLJS.K.g(e,$CLJS.N_);e=$CLJS.bf.l(R7(a,b,e),$CLJS.n(l)?Q7(a,b,f):null,$CLJS.H([$CLJS.n(k)?Dta(a,b,f):null]));return $CLJS.yf($CLJS.bf.g(e,$CLJS.n(d)?$CLJS.p1(a,b,e,c):null))});
$CLJS.X_.m(null,S7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.K.g(c,$CLJS.J_);var e=Eta(a,b);if($CLJS.n(e))return e;a=$CLJS.E3(a,b);e=Ita(a,b,d);var f=Hta(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.ch(f),$CLJS.Wf.j($CLJS.xf,Cta(function(k){return $CLJS.bk.l(k,$CLJS.aZ,$CLJS.H([$CLJS.LJ,$CLJS.F0,$CLJS.MY]))}),$CLJS.bf.g(f,P7(a,b,c)))):$CLJS.bf.l(R7(a,b,new $CLJS.h(null,2,[$CLJS.D_,!1,$CLJS.J_,d],null)),Q7(a,b,d),$CLJS.H([P7(a,b,c)]))});$CLJS.O_.m(null,$CLJS.bV,function(){return $CLJS.HD("Native query")});
var T7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,$CLJS.OU,$CLJS.EM],null),U7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YE,$CLJS.XE,$CLJS.iV,$CLJS.OQ,$CLJS.kR],null);
$CLJS.O_.m(null,$CLJS.VU,function(a,b,c,d){var e=$CLJS.E3(a,b);a=$CLJS.Be(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.ik.g($CLJS.qB,$CLJS.cf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.Xoa.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[T7,U7],null)),k=$CLJS.hs(" + ",$CLJS.jk.g($CLJS.Hz,$CLJS.cf.g(f,T7)));f=$CLJS.cf.g(f,U7);return $CLJS.hs(", ",$CLJS.jk.g($CLJS.Hz,$CLJS.ae(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.M1(e,b);return $CLJS.n(a)?$CLJS.n_.v(e,a,$CLJS.FV(e,a),d):null});