var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var rG,sG,uG,vG,xha,yha,zha,wG,tG;$CLJS.pG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.K.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.qG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
rG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.Hk)?$CLJS.aG:$CLJS.Dz(b,$CLJS.Rk)?$CLJS.XF:$CLJS.Dz(b,$CLJS.Kk)?$CLJS.ZF:null;return $CLJS.n(b)?$CLJS.nE(b,a):!0};
sG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.pG(function(d){return $CLJS.Dz($CLJS.KD(d),$CLJS.aC)},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,!1);b=$CLJS.K.g(b,!0);if($CLJS.hk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.KD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(rG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
uG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.hj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(sG(b))].join("")}],null),$CLJS.Oe(sG)],null)],null)};
vG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.qE,$CLJS.cf.g(function(b){var c=$CLJS.KD(b),d=$CLJS.Dz(c,$CLJS.XD);b=d?$CLJS.nE($CLJS.mF,b):d;return $CLJS.n(b)?$CLJS.rj:c},a))};yha=function(a){a=$CLJS.qG(function(b){return!$CLJS.Dz(b,$CLJS.aC)},$CLJS.cf.g($CLJS.KD,a));return $CLJS.Dz(a,$CLJS.XD)?$CLJS.wj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.aC)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.Hk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.KD(c),$CLJS.Kk)},b))?$CLJS.aC:xha(b)};wG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.xG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);tG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,tG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.KD(a);return $CLJS.Le(function(d){return rG(d,c)},b)}],null)],null));
$CLJS.Y(wG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null)],null)],
null);$CLJS.HF.g($CLJS.Pq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,uG($CLJS.Pq),vG($CLJS.Pq)],null));$CLJS.HF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,uG($CLJS.Zr),Aha,vG($CLJS.Zr)],null));$CLJS.xE($CLJS.Qq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,wG],null)]));$CLJS.xE($CLJS.CF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,wG],null)]));
for(var yG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pq,$CLJS.Zr,$CLJS.Qq],null)),zG=null,AG=0,BG=0;;)if(BG<AG){var Bha=zG.X(null,BG);$CLJS.oE(Bha,$CLJS.xG);BG+=1}else{var CG=$CLJS.y(yG);if(CG){var DG=CG;if($CLJS.wd(DG)){var EG=$CLJS.ic(DG),Cha=$CLJS.jc(DG),Dha=EG,Eha=$CLJS.D(EG);yG=Cha;zG=Dha;AG=Eha}else{var Fha=$CLJS.z(DG);$CLJS.oE(Fha,$CLJS.xG);yG=$CLJS.B(DG);zG=null;AG=0}BG=0}else break}
$CLJS.JD.m(null,$CLJS.xG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.vE($CLJS.IE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.oE($CLJS.IE,$CLJS.aE);
for(var FG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,$CLJS.zE,$CLJS.WE],null)),GG=null,HG=0,IG=0;;)if(IG<HG){var Gha=GG.X(null,IG);$CLJS.vE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));IG+=1}else{var JG=$CLJS.y(FG);if(JG){var KG=JG;if($CLJS.wd(KG)){var LG=$CLJS.ic(KG),Hha=$CLJS.jc(KG),Iha=LG,Jha=$CLJS.D(LG);FG=Hha;GG=Iha;HG=Jha}else{var Kha=$CLJS.z(KG);$CLJS.vE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));FG=$CLJS.B(KG);GG=null;HG=0}IG=0}else break}
for(var MG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,$CLJS.gF,$CLJS.nF],null)),NG=null,OG=0,PG=0;;)if(PG<OG){var Lha=NG.X(null,PG);$CLJS.vE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));PG+=1}else{var QG=$CLJS.y(MG);if(QG){var RG=QG;if($CLJS.wd(RG)){var SG=$CLJS.ic(RG),Mha=$CLJS.jc(RG),Nha=SG,Oha=$CLJS.D(SG);MG=Mha;NG=Nha;OG=Oha}else{var Pha=$CLJS.z(RG);$CLJS.vE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));MG=$CLJS.B(RG);NG=null;OG=0}PG=0}else break}$CLJS.vE($CLJS.FE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));
$CLJS.JD.m(null,$CLJS.FE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.KD(b),$CLJS.bj)&&$CLJS.Dz($CLJS.KD(a),$CLJS.bj)?$CLJS.bj:$CLJS.LC});